import React from 'react';

const 
WorkshopsDetails = () => {

    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                   
                    <div className="content-box">
                        
                        <h2>Unlock the Potential of Your Team with Our Workshops</h2>
                        
                        <div className="row two-column">
                        
                            <div className="column col-lg-12 col-md-12">
                                <ul className="list-style-two">
                                    <li>Workshops foster collaboration and hands-on learning, providing participants with actionable skills and deeper engagement.</li>
                                    <li>Our team ensures a seamless experience by organizing workshops tailored to specific audiences and industries.</li>
                                    <li>We create engaging environments with state-of-the-art tools and resources to maximize learning outcomes.</li>
                                    <li>Promotional strategies like custom websites, social media campaigns, and attractive materials guarantee high attendance and impact.</li>
                                </ul>
                            </div>
                        </div>
                        <p>Our extensive network allows us to collaborate with expert speakers, ensuring content quality and relevance for attendees.</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WorkshopsDetails;