import React from 'react';

const ConceptCreationDetails = () => {
    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="content-box">
                        <h2>Concept Creation: What We Do</h2>
                        
                        <p>
                            Our team specializes in setting clear objectives to creatively design and develop the overall branding and image for your meetings or events. We work closely with you to ensure that the concept aligns with your goals and audience, enhancing the event's impact and appeal.
                        </p>

                        <h3>Our Approach to Concept Creation Includes:</h3>
                        <ul className="list-style-two">
                            <li>Setting objectives to creatively design and develop the overall branding and image for the meetings or events.</li>
                            <li>Assisting in setting proper pricing strategies for the event to ensure profitability and attractiveness.</li>
                            <li>Developing sponsorship and exhibition opportunities to create valuable partnerships and increase event revenue.</li>
                            <li>Managing the event budget efficiently, ensuring all resources are allocated effectively.</li>
                            <li>Building effective strategic alliances to enhance event success and long-term relationships.</li>
                        </ul>

                        <p>
                            With our expertise and a wide range of industry contacts, we provide the best services at competitive prices, ensuring that your event stands out in every aspect. We constantly innovate to make each event a unique and memorable experience.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ConceptCreationDetails;
