import React, { useEffect, useState } from "react";
import axios from "axios";
import Input from "../../CoreComponent/Input";
import ImageUpload from "../../CoreComponent/ImageUpload";
import DateInput from "../../CoreComponent/Date";
import "./style.scss";
import Select from "../../CoreComponent/Select";
import TextArea from "../../CoreComponent/TextArea";
import SVG from "react-inlinesvg";
import deleteIcon from "../../icons/deleteIcon.svg";
import toast from "react-hot-toast";
import moment from "moment";
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import { CloseRounded } from "@mui/icons-material";

const CommitteeForm = ({ committeeMembers, setCommitteeMembers }) => {
  console.log({ committeeMembers });

  const generateId = () => {
    // Generate a random string of characters for the ID
    return Math.random().toString(36).substr(2, 9); // Generates a random string of 9 characters
  };

  const addCommitteeMember = () => {
    setCommitteeMembers([
      ...committeeMembers,
      { id: generateId(), name: "", image: null, country: "" },
    ]);
  };

  const deleteCommitteeMember = (id) => {
    setCommitteeMembers(committeeMembers.filter((member) => member.id !== id));
  };

  const handleNameChange = (id, value) => {
    const updatedMembers = committeeMembers.map((member) =>
      member.id === id ? { ...member, name: value } : member
    );
    setCommitteeMembers(updatedMembers);
  };

  const handleImageChange = (id, file) => {
    const updatedMembers = committeeMembers.map((member) =>
      member.id === id ? { ...member, image: file } : member
    );
    setCommitteeMembers(updatedMembers);
  };
  const handleInputChange = (id, field, value) => {
    const updatedMembers = committeeMembers.map((member) =>
      member.id === id ? { ...member, [field]: value } : member
    );
    setCommitteeMembers(updatedMembers);
  };

  return (
    <div className="committee-form-container">
      <div className="title-committee"> Committee Members</div>
      <div className="button-section-container">
        <Button className="add-button-committee" onClick={addCommitteeMember}>
          Add Member
        </Button>
      </div>

      {committeeMembers.map((member) => (
        <div key={member.id} className="committee-member">
          <div className="member-info">
            <Input
              type="text"
              label="Name"
              placeholder="Enter name"
              inputValue={member.name}
              setInputValue={(value) => handleNameChange(member.id, value)}
              className="name-input"
            />

            <ImageUpload
              label="Upload Image"
              inputValue={member.image}
              setInputValue={(file) => handleImageChange(member.id, file)}
              allowedExtensions={["jpg", "jpeg", "png"]}
              required={false}
            />
            <Input
              type="text"
              label="Country"
              placeholder="Country"
              inputValue={member.country || ""} // هنا نمرر القيمة الحالية للبلد من المصفوفة
              setInputValue={(value) => handleInputChange(member.id, 'country', value)} // تحديث الحقل country
              className="name-input"
            />
          </div>

          <Button
            className="delete-button-committee"
            onClick={() => deleteCommitteeMember(member.id)}
          >
            Delete
          </Button>
        </div>
      ))}
    </div>
  );
};

const PriceForm = ({ entries, setEntries }) => {
  const addEntry = () => {
    setEntries([
      ...entries,
      { id: Date.now(), price_type: "", price: "", description: "" },
    ]);
  };

  const deleteEntry = (id) => {
    setEntries(entries.filter((entry) => entry.id !== id));
  };

  const handleInputChange = (id, key, value) => {
    const updatedEntries = entries.map((entry) =>
      entry.id === id ? { ...entry, [key]: value } : entry
    );
    setEntries(updatedEntries);
  };
  return (
    <div className="price-form-container">
      <div className="price-header">Add Pricing Information</div>
      <div className="button-section-container">
        <Button className="add-button-pricing" onClick={addEntry}>
          Add Entry
        </Button>
      </div>
      {entries.map((entry) => (
        <div key={entry.id} className="entry-row">
          <Input
            label="Price Type"
            placeholder="Enter Price Type"
            inputValue={entry.price_type}
            setInputValue={(value) =>
              handleInputChange(entry.id, "price_type", value)
            }
            type="text"
          />

          <Input
            label="Price (USD)"
            placeholder="Enter Price (USD)"
            inputValue={entry.price}
            setInputValue={(value) =>
              handleInputChange(entry.id, "price", value)
            }
            type="number"
          />

          <Input
            label="Description"
            placeholder="Enter Description"
            inputValue={entry.description}
            setInputValue={(value) =>
              handleInputChange(entry.id, "description", value)
            }
            type="text"
          />

          <Button
            className="delete-button-entry"
            onClick={() => deleteEntry(entry.id)}
          >
            Delete
          </Button>
        </div>
      ))}{" "}
    </div>
  );
};

const EditConferencesAdmin = ({
  setIsOpen,
  getConference,
  setConference,
  conferenceData,
}) => {
  const BaseUrl = process.env.REACT_APP_BASE_URL;
  const [logo, setLogo] = useState(""); // For storing logo URL or name
  const [secondLogo, setSecondLogo] = useState(""); // For a secondary logo
  const [country, setCountry] = useState(""); // For country name
  const [visaPrice, setVisaPrice] = useState(0); // For visa price

  const [committeeMembers, setCommitteeMembers] = useState([
    { id: Date.now(), name: "", image: null },
  ]);
  console.log(
    setIsOpen,
    getConference,
    setConference,
    conferenceData,

  )
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("upcoming");
  const [image, setImage] = useState(null);
  const [firstAnnouncement, setFirstAnnouncement] = useState(null);
  const [secondAnnouncement, setSecondAnnouncement] = useState(null);
  const [brochure, setBrochure] = useState(null);
  const [scientificProgram, setScientificProgram] = useState(null);
  const [companionDinnerPrice, setCompanionDinnerPrice] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  const [errors, setErrors] = useState({});

  const [entries, setEntries] = useState([
    { id: Date.now(), price_type: "", price: "", description: "" },
  ]);
  const [topics, setTopics] = useState([""]);

  function convertPriceToObject(data) {
    let result = {};

    data.forEach((item, index) => {
      result[`prices[${index}][price_description]`] = item.description;
      result[`prices[${index}][price]`] = item.price;
      result[`prices[${index}][price_type]`] = item.price_type;
    });

    return result;
  }
  useEffect(() => {
    return () => {
      setConference(null);
    };
  }, []);
  useEffect(() => {
    if (conferenceData) {
      console.log({ conferenceData });
      console.log(conferenceData.start_date);
      setLogo(conferenceData?.logo)
      setSecondLogo(conferenceData?.second_logo)
      setVisaPrice(conferenceData?.visa_price)
      setCountry(conferenceData?.country)
      setCompanionDinnerPrice(conferenceData?.companion_dinner_price)
      // تحديث الحقول الرئيسية
      setTitle(conferenceData.title || "");
      setDescription(conferenceData.description || "");
      setStartDate(
        conferenceData?.start_date
          ? conferenceData?.start_date?.split("T")[0]
          : ""
      );
      setEndDate(
        conferenceData?.end_date ? conferenceData?.end_date?.split("T")[0] : ""
      );
      // setStartDate(conferenceData.start_date || "");
      // setEndDate(conferenceData.end_date || "");
      setLocation(conferenceData.location || "");
      setStatus(conferenceData.status || "upcoming");
      setFirstAnnouncement(conferenceData.first_announcement_pdf || "");
      setSecondAnnouncement(conferenceData.second_announcement_pdf || "");
      setBrochure(conferenceData.conference_brochure_pdf || "");
      setScientificProgram(
        conferenceData.conference_scientific_program_pdf || ""
      );
      // تحديث أعضاء اللجنة
      const committee = conferenceData.committee_members.map((member) => ({
        id: member.id,
        name: member.name,
        image: member.committee_image,
        country: member.country
      }));
      setCommitteeMembers(
        committee.length > 0
          ? committee
          : [{ id: Date.now(), name: "", image: null }]
      );

      // تحديث المواضيع
      const topicsData = conferenceData.scientific_topics.map(
        (topic) => topic.title
      );
      setTopics(topicsData.length > 0 ? topicsData : [""]);

      // تحديث الأسعار
      const entriesData = conferenceData.prices.map((price) => ({
        id: Date.now(), // قم بتغييره ليعكس `id` المناسب إذا كانت موجودة
        price_type: price.price_type || "",
        price: price.price || "",
        description: price.description || "",
      }));
      setEntries(
        entriesData.length > 0
          ? entriesData
          : [{ id: Date.now(), price_type: "", price: "", description: "" }]
      );
    }
  }, [conferenceData]);

  // Handler to update topics array
  const handleTopicChange = (index, newValue) => {
    const updatedTopics = [...topics];
    updatedTopics[index] = newValue;
    setTopics(updatedTopics);
  };

  // Add a new input for topic
  const handleAddTopic = () => {
    setTopics([...topics, ""]);
  };

  // Remove a topic input
  const handleRemoveTopic = (index) => {
    const updatedTopics = topics.filter((_, i) => i !== index);
    setTopics(updatedTopics);
  };

  async function addCommitteeMembers(mainId, members) {
    if (!mainId) return;
    const formData = new FormData();
    const token = localStorage.getItem("token");
    members.forEach((member, index) => {
      formData.append(`members[${index}][name]`, member.name);
      formData.append(`members[${index}][country]`, member.country);

      if (member.image) {
        formData.append(`members[${index}][committee_image]`, member.image);
      }
      formData.append(`members[${index}][conference_id]`, mainId);
      if (typeof member.id === "number" || !isNaN(parseInt(member.id))) {
        formData.append(`members[${index}][id]`, member.id);
      } else {
        formData.append(`members[${index}][id]`, null);
      }
    });

    try {
      const response = await axios.post(`${BaseUrl}/con/committee`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setIsOpen(false);
      getConference();
    } catch (error) { }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const prices = convertPriceToObject(entries);

      const formData = new FormData();
      const allEmpty = Object.values(prices).every((value) => value === "");

      if (!allEmpty) {
        for (const key in prices) {
          if (prices.hasOwnProperty(key)) {
            formData.append(key, prices[key]);
          }
        }
      }

      formData.append("title", title);
      formData.append("country", country);
      formData.append("visa_price", visaPrice);
      formData.append("logo", logo);
      formData.append("second_logo", secondLogo);
      formData.append("description", description);
      formData.append("start_date", startDate);
      formData.append("end_date", endDate);
      formData.append("location", location);
      formData.append(
        "status",
        typeof status == "string" ? status : status.value
      );
      formData.append("image", image);
      formData.append("first_announcement_pdf", firstAnnouncement);
      formData.append("second_announcement_pdf", secondAnnouncement);
      formData.append("conference_brochure_pdf", brochure);
      formData.append("conference_scientific_program_pdf", scientificProgram);
      formData.append("scientific_topics", topics);
      formData.append("companion_dinner_price", companionDinnerPrice);

      formData.append("timestamps", new Date().toISOString());
      const token = localStorage.getItem("token");
      axios
        .post(`${BaseUrl}/conferences/${conferenceData?.id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const id = response.data.id;
          toast.success("Conference Edited successfully!");
          setIsOpen(false);
          addCommitteeMembers(id, committeeMembers);
        })
        .catch((error) => {
          console.error("Error submitting data: ", error);
        });
    }
  };
  const validateForm = () => {
    let validationErrors = {};

    return Object.keys(validationErrors).length === 0;
  };
  return (
    <Drawer
      anchor="right"
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1,
        "& .MuiDrawer-paper": {
          width: { xs: "100%", sm: "100%", md: "50%" },
          padding: "24px",
          boxShadow: "0 4px 12px rgba(0,0,  0,0.1)",
        },

      }}
      open={setIsOpen}
      onClose={() => setIsOpen(false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 2,
        }}
      >
        <IconButton onClick={() => setIsOpen(false)}>
          <CloseRounded />
        </IconButton>
      </div>
      <Typography
        variant="h6"

        sx={{
          color: "#c62828",
          textAlign: "center",
          backgroundColor: "#f1f1f1",
          padding: 1,
          borderRadius: 1,
          marginBottom: 2,
        }}

        gutterBottom
      >
        Edit Conference
      </Typography>

      <Box sx={{
        padding: 2,
        overflowY: 'auto'
      }}>

        <Input
          label="Title"
          placeholder="Enter title"
          inputValue={title}
          setInputValue={setTitle}
          type="text"
          required
          errorMsg={errors.title}
        />
        <TextArea
          label="Description"
          placeholder="Enter description"
          value={description}
          setValue={setDescription}
          required
          errorMsg={errors.description}
        />
        <DateInput
          label="Start Date"
          placeholder="YYYY-MM-DD"
          inputValue={startDate}
          setInputValue={setStartDate}
          required
          errorMsg={errors.startDate}
        />
        <DateInput
          label="End Date"
          placeholder="YYYY-MM-DD"
          inputValue={endDate}
          setInputValue={setEndDate}
          required
          errorMsg={errors.endDate}
        />
        <Input
          label="Location"
          placeholder="Enter location"
          inputValue={location}
          setInputValue={setLocation}
          required
          errorMsg={errors.location}
        />
        <Input
          label="Companion Dinner Cost (USD)"
          placeholder="Enter companion Dinner Price Cost"
          inputValue={companionDinnerPrice}
          setInputValue={setCompanionDinnerPrice}
          type="number"
          required
        />

        <ImageUpload
          label="Upload Image"
          inputValue={image}
          setInputValue={setImage}
          existingFile={conferenceData.image}
          allowedExtensions={["jpg", "jpeg", "png"]}
          errorMsg={errors.image}
        />

        <ImageUpload
          label="First Announcement PDF"
          inputValue={firstAnnouncement}
          setInputValue={setFirstAnnouncement}
          existingFile={conferenceData.first_announcement_pdf}
          allowedExtensions={["pdf"]}
          errorMsg={errors.firstAnnouncement}
        />

        <ImageUpload
          label="Second Announcement PDF"
          inputValue={secondAnnouncement}
          setInputValue={setSecondAnnouncement}
          existingFile={conferenceData.second_announcement_pdf}
          allowedExtensions={["pdf"]}
          errorMsg={errors.secondAnnouncement}
        />

        <ImageUpload
          label="Conference Brochure PDF"
          inputValue={brochure}
          setInputValue={setBrochure}
          allowedExtensions={["pdf"]}
          existingFile={conferenceData.conference_brochure_pdf}
          errorMsg={errors.brochure}
        />

        <ImageUpload
          label="Conference Scientific Program PDF"
          inputValue={scientificProgram}
          setInputValue={setScientificProgram}
          allowedExtensions={["pdf"]}
          existingFile={conferenceData.conference_scientific_program_pdf}
          errorMsg={errors.scientificProgram}
        />
        <ImageUpload
          label="Logo"
          inputValue={logo}
          setInputValue={setLogo}
          allowedExtensions={["jpg", "jpeg", "png", "gif"]}
        // existingFile={conferenceData.conference_scientific_program_pdf}
        // errorMsg={errors.scientificProgram}
        />
        <Input
          label="Visa price"
          // placeholder="Enter companion Dinner Price Cost"
          inputValue={visaPrice}
          setInputValue={setVisaPrice}
          type="number"
          required
        />
        <Input
          label="Country"
          // placeholder="Enter companion Dinner Price Cost"
          inputValue={country}
          setInputValue={setCountry}
          required
        />
        <ImageUpload
          label=" Second Logo"
          inputValue={secondLogo}
          setInputValue={setSecondLogo}
          allowedExtensions={["jpg", "jpeg", "png", "gif"]}

        />
        <div className="topics-container">
          <div className="topic-title">
            Topics
            <span className="star">*</span>
          </div>
          <div className="topics-container-inputs">
            {topics.map((topic, index) => (
              <div key={index} className="topic-input-container">
                <Input
                  placeholder="Enter a topic"
                  inputValue={topic}
                  setInputValue={(newValue) =>
                    handleTopicChange(index, newValue)
                  }
                />
                <SVG
                  className="delete-icon"
                  src={deleteIcon}
                  onClick={() => handleRemoveTopic(index)}
                />
              </div>
            ))}
            <div className="add-topic-btn-container">
              <Button
                type="button"
                onClick={handleAddTopic}
                className="add-topic-btn"
              >
                Add Topic
              </Button>
            </div>
          </div>
        </div>

        <PriceForm entries={entries} setEntries={setEntries} />
        <CommitteeForm
          committeeMembers={committeeMembers}
          setCommitteeMembers={setCommitteeMembers}
        />
        <Button
          sx={{
            backgroundColor: '#c62828',// Modern vibrant red

            marginTop: "20px",
            color: "#fff",
            width: "100%",
            "&:hover": {
              backgroundColor: "#e63946",
              color: "#fff",
            }
          }}

          onClick={handleSubmit}>
          Edit
        </Button>
      </Box>


    </Drawer>

  );
};

export default EditConferencesAdmin;
