import React from 'react';
import { HashLink as Link } from 'react-router-hash-link'
import WhyChooseV1Data from '../../jsonData/whyChoose/WhyChooseV1Data.json'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";


const WhyChooseV1 = () => {
    return (
        <>
            <section className="why-choose-us">
                <div className="auto-container">
                    <div className="row">
                        <div className="content-column col-lg-6 col-md-12 col-sm-12 order-lg-2  ">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <h2>Why Choose Us for Your Conferences and Events?</h2>
                                </div>
                                <ul className="list-style-one">
                                    {WhyChooseV1Data.map(choose =>
                                    <>
                                     <li key={choose.id}>{choose.list}</li>
                                     <div className="text">{choose.text}</div>
                                    </>
                                       

                                    )}
                                </ul>
                                <div className="btn-box">
                                    <Link to="/contact_us#" className="theme-btn btn-style-two"><span className="btn-title">Be one of our client</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="image-column col-lg-6 col-md-12 col-sm-12 " style={{
                            margin:'auto'
                        }}>
                            <Swiper
            modules={[ Autoplay]}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            slidesPerView={1}
        >
            <SwiperSlide>
                <div className="image-box" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <figure className="image">
                        <img src="../images/background/3.jpeg" alt="Slide 1" />
                    </figure>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="image-box">
                    <figure className="image">
                        <img src="../images/background/4.jpg" alt="Slide 2" />
                    </figure>
                </div>
            </SwiperSlide>
          
        </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WhyChooseV1;