import React from 'react';

const AdditionalConferenceExhibitionServicesDetails = () => {
    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="content-box">
                        <h2>Expanding Excellence: Additional Services for Conferences and Exhibitions</h2>
                        
                        <p>
                            To enhance the success of your events, we proudly offer an extensive range of additional services designed to meet the evolving needs of our clients:
                        </p>

                        <h3>1. Data Analysis and Reporting</h3>
                        <ul className="list-style-two">
                            <li>In-depth analysis of event performance using advanced tools.</li>
                            <li>Detailed reports on attendee engagement and participation.</li>
                            <li>Measurement of event ROI for informed decision-making.</li>
                        </ul>

                        <h3>2. Guest and Participant Management</h3>
                        <ul className="list-style-two">
                            <li>VIP reception and hospitality services.</li>
                            <li>Invitation management and attendance confirmations.</li>
                            <li>Interactive applications to optimize attendee experience.</li>
                        </ul>

                        <h3>3. Workshops and Seminars Coordination</h3>
                        <ul className="list-style-two">
                            <li>Customized workshop design and execution.</li>
                            <li>Provision of advanced training tools and facilities.</li>
                            <li>Organization of specialized seminars featuring industry experts.</li>
                        </ul>

                        <h3>4. Virtual and Hybrid Event Solutions</h3>
                        <ul className="list-style-two">
                            <li>Creation of interactive platforms for virtual events.</li>
                            <li>High-quality live streaming with comprehensive technical support.</li>
                            <li>Hybrid event setups blending physical and virtual participation.</li>
                        </ul>

                        <h3>5. Specialized Consultancy Services</h3>
                        <ul className="list-style-two">
                            <li>Strategic advice on event planning and execution.</li>
                            <li>Innovative marketing strategies to maximize event reach.</li>
                        </ul>

                        <h3>6. Branding and Promotional Services</h3>
                        <ul className="list-style-two">
                            <li>Comprehensive branding solutions, including visual identity creation.</li>
                            <li>Provision of customized promotional gifts.</li>
                            <li>Management of digital and print advertising campaigns.</li>
                        </ul>

                        <h3>7. Corporate and Special Event Management</h3>
                        <ul className="list-style-two">
                            <li>Coordination of high-profile launch events.</li>
                            <li>Organization of award ceremonies and official gatherings.</li>
                            <li>Tailored corporate events for enhanced internal and external engagement.</li>
                        </ul>

                        <h3>8. Sustainable Event Solutions</h3>
                        <ul className="list-style-two">
                            <li>Planning eco-friendly, sustainable events.</li>
                            <li>Use of recyclable materials in exhibitions and conferences.</li>
                            <li>Offering solutions to minimize the event’s carbon footprint.</li>
                        </ul>

                        <h3>9. Interactive Experience Design</h3>
                        <ul className="list-style-two">
                            <li>Development of immersive spaces with VR and AR technologies.</li>
                            <li>Creation of unique experiences to boost audience engagement.</li>
                        </ul>

                        <h3>10. Printing and Interior Design Services</h3>
                        <ul className="list-style-two">
                            <li>High-quality design and printing of marketing materials.</li>
                            <li>Innovative interior designs for standout exhibition spaces.</li>
                        </ul>

                        <p>
                            With these additional services, we provide a one-stop solution for all your event needs, ensuring a seamless and memorable experience that exceeds expectations.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AdditionalConferenceExhibitionServicesDetails;
