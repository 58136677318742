import React from 'react';

const 
ExhibitionsDetails = () => {

    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                   
                    <div className="content-box">
                        
                        <h2>Why Choose Our Expositions & Exhibitions Services</h2>
                        
                        <div className="row two-column">
                        
                            <div className="column col-lg-12 col-md-12">
                                <ul className="list-style-two">
                                    <li>Events consultant is specialized in organizing Medical, Tourism and Scientific Exhibitions. To prepare and realize successful exhibitions a skillful organizing support is essential.</li>
                                    <li>Our staff offers to associations, ministries, academies, institutions and foundations all the services which are necessary to organize exhibitions satisfying every standard. We support our clients in the research and choice of the best locations.</li>
                                    <li>We are experienced in the demanding marketing, promoting and public relations activities and the creation of typographic material and posters which communicate a strong image.</li>
                                    <li>Events consultant also proposes creative solutions and the best locations for related events such as workshops, seminars and openings and gala evenings.</li>
                                </ul>
                            </div>
                        </div>
                        <p>We can also include among the options of the event a multimedia room, meeting rooms, bookshop, and coffee area and cyber locations.</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ExhibitionsDetails;