import React from 'react';

const PlanningDetails = () => {
    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="content-box">

                        <h2>Success Doesn't Just Happen—It’s Planned</h2>

                        <p>
                            Success is never accidental; it’s the result of meticulous planning. In the realm of event management, careful planning is the foundation that ensures every detail aligns perfectly to create an unforgettable experience.
                        </p>

                        <p>Effective event planning is crucial because it:</p>
                        <ul className="list-style-two">
                            <li>Sets the stage for seamless execution.</li>
                            <li>Anticipates potential challenges.</li>
                            <li>Maximizes the impact of the event.</li>
                        </ul>

                        <p>
                            At <strong>Events Consultant</strong>, we are committed to mastering the art of planning. You can trust that we consistently deliver outstanding results. Our expert planning and budgeting processes guarantee:
                        </p>
                        <ul className="list-style-two">
                            <li>Meeting deadlines.</li>
                            <li>Avoiding budget overruns.</li>
                        </ul>

                        <p>
                            This allows you to focus on the success of your event without concern. The importance of planning cannot be overstated—it’s what transforms a vision into reality. By prioritizing detailed preparation, we ensure that:
                        </p>
                        <ul className="list-style-two">
                            <li>Each event runs smoothly.</li>
                            <li>Objectives are met.</li>
                            <li>A lasting impression is left on all attendees.</li>
                        </ul>

                        <p>
                            With <strong>Events Consultant</strong>, you are not just planning an event; you are crafting a memorable experience that reflects your goals and values.
                        </p>

                    </div>
                </div>
            </section>
        </>
    );
};

export default PlanningDetails;
