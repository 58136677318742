import React from 'react';

const CorporateMeetingsDetails = () => {
    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="content-box">

                        <h2>Why Conferences Matter</h2>
                        <div className="row two-column">
                            <div className="column col-lg-12 col-md-12">
                                <ul className="list-style-two">
                                    <li>Conferences provide opportunities for networking, socializing, and hearing the latest management insights.</li>
                                    <li>They can also serve as forums for creative problem-solving and mobilizing delegates into meaningful action with proper guidance and support.</li>
                                    <li>The stage setting for a conference—set design, lighting, sound quality, and projection—is critical for effective communication and message delivery.</li>
                                </ul>
                            </div>
                        </div>
                        <p>
                            Our skilled staff carefully manages all phases of a conference, including planning, preparation, and execution. With years of experience in events management, we optimize resources to deliver successful results for our clients.
                        </p>
                        <p>
                            Our services include:
                        </p>
                        <div className="row two-column">
                            <div className="column col-lg-12 col-md-12">
                                <ul className="list-style-two">
                                    <li>Budget formulation and efficient preparation management.</li>
                                    <li>Multilingual assistance and translation services.</li>
                                    <li>Technical and audio-visual support to meet diverse requirements.</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default CorporateMeetingsDetails;
