import React from 'react';

const ComprehensiveMarketingServicesDetails = () => {
    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="content-box">
                        <h2>Comprehensive Marketing Services</h2>
                        
                        <p>
                            We provide professional marketing services across all fields, ensuring a tailored approach that aligns with your specific needs. Our expertise extends to social media marketing, where we leverage all platforms effectively to enhance your visibility and promote your business. Whether it’s increasing brand awareness, showcasing your services, or driving sales for your products, our strategies are designed to deliver impactful and professional results that resonate with your target audience.
                        </p>

                        <h3>Our Comprehensive Marketing Services Include:</h3>
                        <ul className="list-style-two">
                            <li>Social Media Marketing: Creating and executing strategies to maximize your presence across social platforms.</li>
                            <li>Brand Strategy: Helping define and communicate your brand’s unique value and identity.</li>
                            <li>Content Marketing: Crafting compelling content that resonates with your audience and drives engagement.</li>
                            <li>Email Marketing: Developing and managing email campaigns to increase customer engagement and retention.</li>
                            <li>Search Engine Optimization (SEO): Optimizing your online presence to enhance search engine rankings and visibility.</li>
                            <li>Paid Media Campaigns: Managing paid advertising across digital channels to drive targeted traffic and conversions.</li>
                            <li>Influencer Partnerships: Collaborating with influencers to enhance brand credibility and reach.</li>
                        </ul>

                        <p>
                            Whether you're looking to increase brand awareness, engage with your audience on social media, or generate leads through targeted campaigns, we provide comprehensive and customized marketing solutions that deliver measurable results.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ComprehensiveMarketingServicesDetails;
