import React from 'react';

const 
ConferencesDetails = () => {

    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                   
                    <div className="content-box">
                        
                        <h2>Why Choose Our Conference Services</h2>
                        
                        <div className="row two-column">
                        
                            <div className="column col-lg-12 col-md-12">
                                <ul className="list-style-two">
                                    <li>Conferences have traditionally provided opportunities for networking, socializing, and hearing the latest management speak. But they have rarely been exploited to their full potential as a forum for creative problem-solving and for mobilizing delegates into meaningful action.</li>
                                    <li>The stage setting for any conference is critical to the reception of the message. Set design, lighting, quality of sound, and projection all contribute to communication.</li>
                                    <li>Our very skilled staff carefully provides for all phases of a conference, such as planning, preparation, and management. Our knowledge, based on years of experience in the field of events management, allows us to use resources in the best way possible for successful results.</li>
                                    <li>A strong engagement in public relations, marketing, and promotion is mandatory for attracting and keeping participants. This includes contacting newspapers, preparing press releases, organizing press conferences, creating websites, and designing brochures and posters.</li>
                                </ul>
                            </div>
                        </div>
                        <p>We have a wide range of contacts with the best suppliers, enabling us to offer top-tier services at competitive prices. We constantly seek new and innovative ideas to make our events unique and unforgettable.</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ConferencesDetails;