import React, { useEffect, useState } from 'react';
import BannerV1 from '../../components/banner/BannerV1';
import ComingSoonV1 from '../../components/comingSoon/ComingSoonV1';
import AboutV1 from '../../components/about/AboutV1';
import FeatureV1 from '../../components/feature/FeatureV1';
import SpeakerV1 from '../../components/speaker/SpeakerV1';
import FactV1 from '../../components/fact/FactV1';
import ScheduleV1 from '../../components/schedule/ScheduleV1';
import PriceV1 from '../../components/price/PriceV1';
import VideoV1 from '../../components/video/VideoV1';
import WhyChooseV1 from '../../components/whyChoose/WhyChooseV1';
import ClientV1 from '../../components/client/ClientV1';
import RegisterV1 from '../../components/form/RegisterV1';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import ScheduleV11 from '../../components/schedule/ScheduleV11';
import AboutM from '../../components/about/AboutM';


const Home1 = () => {
    const [allConferences, setAllConferences] = useState([]);
  const navigate = useNavigate();

  const getConference = () => {
    const BaseUrl = process.env.REACT_APP_BASE_URL;
    const url = `${BaseUrl}/con/upcoming`;
    const token = localStorage.getItem("token");

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAllConferences(response.data.upcoming_conferences);
      })
      .catch(() => {
        toast.error("Error fetching conferences");
      });
  };

  useEffect(() => {
    getConference();
  }, []);

  const handleConferenceClick = (conferenceId) => {
    navigate(`/conference/details/${conferenceId}`);
  };
    return (
        <>
            <div className="page-wrapper" style={{
              marginTop:'10vh'
            }}>
                <BannerV1 />
                <AboutV1 />
                <FeatureV1 />
                <SpeakerV1 />
                <FactV1 />
                <ScheduleV11/>
                <AboutM />


                <ScheduleV1 />
                <PriceV1 />
                <VideoV1 />
                <WhyChooseV1 />
                <ClientV1 />
                <RegisterV1 />
                {/* <BlogV1 hasShape={true} /> */}
            </div>
        </>
    );
};

export default Home1;