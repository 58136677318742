import React from 'react';

const ComprehensiveConferenceManagementDetails = () => {
    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="content-box">
                        <h2>Comprehensive Conference Management Services</h2>
                        
                        <p>
                            At Events Consultant, we specialize in delivering comprehensive conference management services designed to guarantee the success of international conferences, summits, and congresses. Our holistic approach covers every aspect of the event management life cycle—from meticulous planning and seamless execution to efficient post-conference operations.
                        </p>

                        <p>
                            We are committed to creating exceptional experiences, offering a wide range of tailored services, including:
                        </p>

                        <ul className="list-style-two">
                            <li>Project and content management</li>
                            <li>Abstract handling and agenda facilitation</li>
                            <li>Securing patronage and sponsorships</li>
                            <li>Finance and accounting management</li>
                            <li>Halls setup and venue logistics</li>
                            <li>Website development and IT support</li>
                            <li>Marketing, communication, and delegate management</li>
                            <li>Entertainment, decor, and onsite services</li>
                            <li>Team-building activities and simultaneous translation</li>
                            <li>Tourist packages, transportation, and car rentals</li>
                            <li>Visa assistance and social event coordination</li>
                            <li>Post-conference reporting and data analysis</li>
                        </ul>

                        <p>
                            From online and onsite registration to entertainment planning, we ensure a smooth and memorable experience for all stakeholders. Trust us to manage every detail, so you can focus on achieving your event’s goals.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ComprehensiveConferenceManagementDetails;
