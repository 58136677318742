import React from 'react';

const LogisticSecretarialDetails = () => {
    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="content-box">

                        <h2>Our Services Include</h2>
                        
                        <div className="row two-column">
                            <div className="column col-lg-12 col-md-12">
                                <ul className="list-style-two">
                                    <li><strong>Coordination:</strong> Liaise with the event initiator, speakers, participants, and suppliers to ensure smooth communication and execution.</li>
                                    <li><strong>Communication:</strong> Mail invitations, registration forms, and essential information to all relevant parties.</li>
                                    <li><strong>Abstract Management:</strong> Receive and manage abstracts for review and inclusion in the event.</li>
                                    <li><strong>Registration Handling:</strong> Process registration forms and participants' fees, ensuring all data is accurately recorded.</li>
                                    <li><strong>Invoicing:</strong> Prepare and mail invoices to participants and sponsors as required.</li>
                                    <li><strong>Document Management:</strong> Update and maintain all event documents and lists to reflect the latest information.</li>
                                    <li><strong>Payments:</strong> Handle payments for event-related expenses in a timely manner.</li>
                                    <li><strong>On-Site Registration:</strong> Oversee site registration procedures, including the distribution of badges, conference kits, and other materials.</li>
                                </ul>
                            </div>
                        </div>
                        
                        <p>
                            Our comprehensive logistic and secretarial services are designed to ensure that every administrative detail is managed efficiently, 
                            allowing you to focus on delivering a successful event.
                        </p>

                    </div>
                </div>
            </section>
        </>
    );
};

export default LogisticSecretarialDetails;
