import React from 'react';

const 
SeminarsDetails = () => {

    return (
        <>
            <section className="event-detail">
                <div className="auto-container">
                   
                    <div className="content-box">
                        
                        <h2>Elevate Your Seminars to Inspire and Engage</h2>
                        
                        {/* <div className="row two-column">
                        
                            <div className="column col-lg-12 col-md-12">
                                <ul className="list-style-two">
                                    <li>Conferences have traditionally provided opportunities for networking, socializing, and hearing the latest management speak. But they have rarely been exploited to their full potential as a forum for creative problem-solving and for mobilizing delegates into meaningful action.</li>
                                    <li>The stage setting for any conference is critical to the reception of the message. Set design, lighting, quality of sound, and projection all contribute to communication.</li>
                                    <li>Our very skilled staff carefully provides for all phases of a conference, such as planning, preparation, and management. Our knowledge, based on years of experience in the field of events management, allows us to use resources in the best way possible for successful results.</li>
                                    <li>A strong engagement in public relations, marketing, and promotion is mandatory for attracting and keeping participants. This includes contacting newspapers, preparing press releases, organizing press conferences, creating websites, and designing brochures and posters.</li>
                                </ul>
                            </div>
                        </div> */}
                        <p>Conferences have traditionally provided opportunities for networking, socializing, and hearing the latest management speak. However, they have rarely been exploited to their full potential as a forum for creative problem-solving and for mobilizing delegates into meaningful action. Our expert event consultants will guide you in achieving this.</p>
                        <p>The stage setting for any conference is critical to the reception of the message. Set design, lighting, quality of sound, and projection all contribute to effective communication. Our highly skilled staff handles all phases of a conference with care, from planning to preparation and management.</p>
                        <p>With years of experience in events management, we leverage our knowledge and resources to deliver successful outcomes for our clients. We offer comprehensive services including budget formulation, multilingual assistance, translations, and technical support to meet all types of requirements.</p>

                    </div>
                </div>
            </section>
        </>
    );
};

export default SeminarsDetails;